.fa-up-down {
  cursor: move;
  color: #777;

  &:hover {
    color: black;
  }
}

a.text-danger:hover {
  font-weight: bold;
  text-decoration: none;
}

.tr {
  display: table;
  width: 100%;
  padding-bottom: 10px;

  & .td {
    display: table-cell;
    vertical-align: top;
    padding-left: 8px;

    &:first-child {
      padding-left: 0;
    }

    &.snug {
      width: 1px;
      white-space: nowrap;
      text-align: center;
    }
  }
}

.description a {
  color: inherit;
  text-decoration: none;
  display: block;
}
