#list_name {
  font-size: 36px;
  margin-top: 20px;
}

#lists .list-group {
  margin-top: 20px;

  & .list-group-item.active .fa-up-down {
    color: inherit;
  }
}

.list-name h1 {
  margin-bottom: 25px;

  & a {
    color: inherit;
    text-decoration: none;
    display:block;
  }
}

div.btn-group {
  vertical-align: baseline;
}
