.fa-up-down {
  cursor: move;
  color: #777;
}
.fa-up-down:hover {
  color: black;
}

a.text-danger:hover {
  font-weight: bold;
  text-decoration: none;
}

.tr {
  display: table;
  width: 100%;
  padding-bottom: 10px;
}
.tr .td {
  display: table-cell;
  vertical-align: top;
  padding-left: 8px;
}
.tr .td:first-child {
  padding-left: 0;
}
.tr .td.snug {
  width: 1px;
  white-space: nowrap;
  text-align: center;
}

.description a {
  color: inherit;
  text-decoration: none;
  display: block;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3JhaWxzL2FwcC92aWV3cy90YXNrcy9jc3MiLCJzb3VyY2VzIjpbImluZGV4LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRTtFQUNBOztBQUVBO0VBQ0U7OztBQUlKO0VBQ0U7RUFDQTs7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7O0FBRUE7RUFDRTtFQUNBO0VBQ0E7O0FBRUE7RUFDRTs7QUFHRjtFQUNFO0VBQ0E7RUFDQTs7O0FBS047RUFDRTtFQUNBO0VBQ0EiLCJzb3VyY2VzQ29udGVudCI6WyIuZmEtdXAtZG93biB7XG4gIGN1cnNvcjogbW92ZTtcbiAgY29sb3I6ICM3Nzc7XG5cbiAgJjpob3ZlciB7XG4gICAgY29sb3I6IGJsYWNrO1xuICB9XG59XG5cbmEudGV4dC1kYW5nZXI6aG92ZXIge1xuICBmb250LXdlaWdodDogYm9sZDtcbiAgdGV4dC1kZWNvcmF0aW9uOiBub25lO1xufVxuXG4udHIge1xuICBkaXNwbGF5OiB0YWJsZTtcbiAgd2lkdGg6IDEwMCU7XG4gIHBhZGRpbmctYm90dG9tOiAxMHB4O1xuXG4gICYgLnRkIHtcbiAgICBkaXNwbGF5OiB0YWJsZS1jZWxsO1xuICAgIHZlcnRpY2FsLWFsaWduOiB0b3A7XG4gICAgcGFkZGluZy1sZWZ0OiA4cHg7XG5cbiAgICAmOmZpcnN0LWNoaWxkIHtcbiAgICAgIHBhZGRpbmctbGVmdDogMDtcbiAgICB9XG5cbiAgICAmLnNudWcge1xuICAgICAgd2lkdGg6IDFweDtcbiAgICAgIHdoaXRlLXNwYWNlOiBub3dyYXA7XG4gICAgICB0ZXh0LWFsaWduOiBjZW50ZXI7XG4gICAgfVxuICB9XG59XG5cbi5kZXNjcmlwdGlvbiBhIHtcbiAgY29sb3I6IGluaGVyaXQ7XG4gIHRleHQtZGVjb3JhdGlvbjogbm9uZTtcbiAgZGlzcGxheTogYmxvY2s7XG59XG4iXX0= */