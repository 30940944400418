#list_name {
  font-size: 36px;
  margin-top: 20px;
}

#lists .list-group {
  margin-top: 20px;
}
#lists .list-group .list-group-item.active .fa-up-down {
  color: inherit;
}

.list-name h1 {
  margin-bottom: 25px;
}
.list-name h1 a {
  color: inherit;
  text-decoration: none;
  display: block;
}

div.btn-group {
  vertical-align: baseline;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3JhaWxzL2FwcC92aWV3cy9saXN0cy9jc3MiLCJzb3VyY2VzIjpbImluZGV4LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRTtFQUNBOzs7QUFHRjtFQUNFOztBQUVBO0VBQ0U7OztBQUlKO0VBQ0U7O0FBRUE7RUFDRTtFQUNBO0VBQ0E7OztBQUlKO0VBQ0UiLCJzb3VyY2VzQ29udGVudCI6WyIjbGlzdF9uYW1lIHtcbiAgZm9udC1zaXplOiAzNnB4O1xuICBtYXJnaW4tdG9wOiAyMHB4O1xufVxuXG4jbGlzdHMgLmxpc3QtZ3JvdXAge1xuICBtYXJnaW4tdG9wOiAyMHB4O1xuXG4gICYgLmxpc3QtZ3JvdXAtaXRlbS5hY3RpdmUgLmZhLXVwLWRvd24ge1xuICAgIGNvbG9yOiBpbmhlcml0O1xuICB9XG59XG5cbi5saXN0LW5hbWUgaDEge1xuICBtYXJnaW4tYm90dG9tOiAyNXB4O1xuXG4gICYgYSB7XG4gICAgY29sb3I6IGluaGVyaXQ7XG4gICAgdGV4dC1kZWNvcmF0aW9uOiBub25lO1xuICAgIGRpc3BsYXk6YmxvY2s7XG4gIH1cbn1cblxuZGl2LmJ0bi1ncm91cCB7XG4gIHZlcnRpY2FsLWFsaWduOiBiYXNlbGluZTtcbn1cbiJdfQ== */